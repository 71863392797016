<template>
  <div class="sms-groups">
    <div v-if="mobilePhones.length === 0" class="empty-text">
      Aucun téléphone
    </div>
    <b-row
      v-for="mobilesGroup of mobilesGroups"
      :key="mobilesGroup.index"
      class="mobiles-group"
      @click="clickGroup(mobilesGroup)"
      :class="{ 'mobiles-group-clicked': isGroupClicked(mobilesGroup), }"
    >
      <b-col cols="11">
        <div v-if="isEmail">
          <a :href="'mailto:?bcc=' + mobilesGroup.asText(selectedSeparator, suffix)">
            {{ mobilesGroup.asText(selectedSeparator, suffix) }}
          </a>
        </div>
        <div v-else>
          {{ mobilesGroup.asText(selectedSeparator, suffix) }}
        </div>
      </b-col>
      <b-col class="text-right" cols="1">
        <a href class="btn btn-xs btn-secondary" @click.prevent="copyMobiles(mobilesGroup)">
          Copier
        </a>
        <div class="small-text">{{ mobilesGroup.values.length }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { distinctElements, subGrouper } from '@/utils/arrays'

export default {
  name: 'SmsList',
  components: {
  },
  props: {
    mobilePhones: Array,
    separator: {
      Type: String,
      defaultValue: ' ',
    },
    smsNumber: {
      type: [Number, String],
      default: 0,
    },
    suffix: {
      Type: String,
      defaultValue: ' ',
    },
  },
  data() {
    return {
      groupClicks: {},
    }
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    isEmail() {
      return this.suffix && this.suffix[0] === '@'
    },
    mobiles() {
      return distinctElements(this.mobilePhones.filter(elt => !!elt))
    },
    selectedSeparator() {
      return this.separator || ','
    },
    mobilesGroups() {
      let inc = ((+this.smsNumber))
      if (!inc) {
        inc = this.mobiles.length
      }
      return subGrouper(this.mobiles, inc)
    },
  },
  methods: {
    isGroupClicked(group) {
      if (this.groupClicks.hasOwnProperty(group.index)) {
        return this.groupClicks[group.index]
      }
      return false
    },
    clickGroup(group) {
      this.groupClicks[group.index] = true
      this.groupClicks = { ...this.groupClicks, }
    },
    copyMobiles(mobilesGroup) {
      const el = document.createElement('textarea')
      el.value = mobilesGroup.asText(this.separator, this.suffix)
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
}
</script>

<style lang="less">
.mobiles-group {
  margin-bottom: 20px;
  padding: 10px;
  background: #f2f2a2;
  word-wrap: break-word;
  white-space: pre-line;
}
.mobiles-group.mobiles-group-clicked {
  background: #cccccc;
}
</style>
