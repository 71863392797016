<template>
  <div class="sms-list-operator-selector" :class="{ 'small-text': smallText, }">
    <b-form-group
      :label="noLabel ? '' : 'Opérateur SMS'"
      description="Pour l'envoi de SMS par un opérateur email, entrez ici le nom de votre opérateur"
    >
      <b-form-input
        type="text"
        v-model="smsSuffix"
        id="smsSuffix"
        :disabled="disabled"
        :class="smallText ? 'small-input' : ''"
      >
      </b-form-input>
    </b-form-group>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'SmsListOperatorSelector',
  data() {
    return {
      smsSuffix: '',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    smallText: {
      type: Boolean,
      defaultValue: false,
    },
    noLabel: {
      type: Boolean,
      defaultValue: false,
    },
  },
  mounted() {
    this.smsSuffix = window.localStorage.getItem('smsSuffix')
  },
  watch: {
    smsSuffix: function() {
      const isValid = (
        (this.smsSuffix === '') ||
        (this.smsSuffix.indexOf('.fr') > 0) ||
        (this.smsSuffix.indexOf('.net') > 0) ||
        (this.smsSuffix.indexOf('.com') > 0)
      )
      if (isValid) {
        window.localStorage.setItem('smsSuffix', this.smsSuffix)
        const value = this.smsSuffix ? ('@' + this.smsSuffix) : ''
        this.$emit('change', { smsSuffix: value, })
      }
    },
  },
}
</script>

<style lang="less">
</style>
