<template>
  <div class="emails-groups">
    <div v-if="emailsGroups.length === 0" class="empty-text">
      Aucun email
    </div>
    <b-row
      v-for="emailGroup of emailsGroups"
      :key="emailGroup.index"
      class="email-group"
      @click="clickEmailGroup(emailGroup)"
      :class="{ 'email-group-clicked': isEmailGroupClicked(emailGroup), }"
    >
      <b-col cols="11">
        <a :href="emailGroup.mailToBcc(selectedSeparator)">
          {{ emailGroup.emailsText(selectedSeparator) }}
        </a>
      </b-col>
      <b-col class="text-right" cols="1">
        <a href class="btn btn-xs btn-secondary" @click.prevent="copyEmailGroup(emailGroup)">
          Copier
        </a>
        <div class="small-text">{{ emailGroup.emails.length }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { emailsGrouper } from '@/utils/emails'

export default {
  name: 'EmailsGroups',
  components: {
  },
  props: {
    emails: Array,
    separator: {
      type: String,
      defaultValue: '',
    },
    emailsNumber: {
      type: [String, Number],
      defaultValue: 25,
    },
  },
  data() {
    return {
      emailsGroupClicks: {},
    }
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    selectedSeparator() {
      return this.separator || ','
    },
    emailsGroups() {
      let inc = +this.emailsNumber
      if (!inc) {
        inc = 25
      }
      return emailsGrouper(this.emails, inc)
    },
  },
  methods: {
    isEmailGroupClicked(emailGroup) {
      if (this.emailsGroupClicks.hasOwnProperty(emailGroup.index)) {
        return this.emailsGroupClicks[emailGroup.index]
      }
      return false
    },
    clickEmailGroup(emailGroup) {
      this.emailsGroupClicks[emailGroup.index] = true
      this.emailsGroupClicks = { ...this.emailsGroupClicks, }
    },
    copyEmailGroup(emailGroup) {
      const el = document.createElement('textarea')
      el.value = emailGroup.emailsText(this.selectedSeparator)
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
}
</script>

<style lang="less">
.email-group {
  margin-bottom: 20px;
  padding: 10px;
  background: #f2f2a2;
  word-wrap: break-word;
  white-space: pre-line;
}

.email-group.email-group-clicked {
  background: #cccccc;
}
</style>
