<template>
  <div class="emails-groups-separator-selector">
    <b-select
      id="emails-groups-separator"
      v-model="selectedSeparator"
      :disabled="disabled"
      :class="smallText ? 'small-text' : ''"
    >
      <b-select-option v-for="separator of separators" :key="separator.separator" :value="separator">
        {{ separator.name }}
      </b-select-option>
    </b-select>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'EmailsGroupsSeparatorSelector',
  data() {
    return {
      selectedSeparator: null,
      separators: [
        { name: 'Virgule', value: ',', },
        { name: 'Point-Virgule', value: ';', },
        { name: 'Espace', value: ' ', },
        { name: 'Saut de ligne', value: '\n', }
      ],
    }
  },
  props: {
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    smallText: {
      type: Boolean,
      defaultValue: false,
    },
  },
  mounted() {
    let separator = window.localStorage.getItem('emailSeparator')
    if (!separator) {
      separator = ''
    }
    const index = this.separators.map(elt => elt.value).indexOf(separator)
    if (index >= 0) {
      this.selectedSeparator = this.separators[index]
    } else {
      this.selectedSeparator = this.separators[0]
    }
  },
  watch: {
    selectedSeparator: function() {
      window.localStorage.setItem('emailSeparator', this.selectedSeparator.value)
      this.$emit('change', { separator: this.selectedSeparator, })
    },
  },
}
</script>

<style lang="less">
</style>
