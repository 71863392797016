export class EmailGroup {
  constructor(
    public index: number,
    public emails: string[]
  ) {
  }

  public emailsText(separator: string = ' ') {
    return this.emails.join(separator)
  }

  public mailTo(separator: string = ','): string {
    return 'mailto:' + this.emails.join(separator)
  }

  public mailToBcc(separator: string = ','): string {
    return 'mailto:?bcc=' + this.emails.join(separator)
  }
}

export function emailsGrouper(emails: (string|string[])[], increment: number = 25): EmailGroup[] {
  const groups: EmailGroup[] = []
  const flatList: string[] = emails.reduce(
    (items: string[], current) => {
      if (Array.isArray(current)) {
        items = items.concat(current)
      } else {
        items.push(current)
      }
      return items
    },
    []
  )
  const uniqueFlatEmails: string[] = flatList.reduce(
    (items: string[], current) => {
      if (items.indexOf(current) < 0) {
        items.push(current)
      }
      return items
    },
    []
  )
  let index = 0
  for (let startIndex = 0; startIndex < uniqueFlatEmails.length; startIndex += increment) {
    let endIndex = startIndex + increment
    endIndex = Math.min(endIndex, uniqueFlatEmails.length)
    const emailSlice: string[] = uniqueFlatEmails.slice(startIndex, endIndex)
    groups.push(
      new EmailGroup(index, emailSlice)
    )
    index += 1
  }
  return groups
}
