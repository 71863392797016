<template>
  <div class="contact-members" v-if="canView">
    <page-header title="Contacter les membres" icon="fa fa-envelope" :links="getLinks()"></page-header>
    <b-row>
      <b-col cols="8">
        <div v-if="showContacts" class="contact-list">
          <loading-gif :loading-name="contactsLoadingName"></loading-gif>
          <div v-if="!isLoading(contactsLoadingName)">
            <div v-if="contacts.length === 0" class="empty-text">
              Aucun contact
            </div>
            <div v-else ref="excelTable">
              <b><counter-label label="contact" :counter="contacts.length"></counter-label></b>
              <table class="table table-striped table-bordered" v-if="showEntityAdresses">
                <tr v-for="contact of contacts" :key="contact.entity.id" class="contact-line">
                  <td>
                    <a href @click.prevent="showEntitySidebar(contact.entity)">
                      {{ getContactName(contact) }}
                    </a>
                  </td>
                  <td>{{ contact.entity.addressText() }}</td>
                  <td>{{ contact.entity.zipCode }}</td>
                  <td>{{ contact.entity.city.name }}</td>
                </tr>
              </table>
              <table class="table table-striped table-bordered" v-if="!showEntityAdresses">
                <tr v-for="contact of contacts" :key="contact.individual.id" class="contact-line">
                  <td>
                    <a href @click.prevent="showIndividualSidebar(contact.individual)">
                      {{ contact.individual.lastName }}
                    </a>
                  </td>
                  <td>{{ contact.individual.firstName }}</td>
                  <td><email-link :email="contact.individual.email"></email-link></td>
                  <td><phone-link :phone="contact.individual.cellPhone"></phone-link></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div v-if="selectedSeparator && showEmails">
          <b><counter-label :counter="emails.length" label="e-mail"></counter-label></b>
          <emails-groups :emails="emails" :separator="selectedSeparator.value" :emails-number="emailsNumber">
          </emails-groups>
        </div>
        <div v-if="selectedSeparator && showMobiles">
          <b><counter-label :counter="mobilePhones.length" label="numéro"></counter-label></b>
          <sms-list
            :mobile-phones="mobilePhones"
            :separator="selectedSeparator.value"
            :suffix="smsSuffix"
            :sms-number="smsNumber"
          >
          </sms-list>
        </div>
      </b-col>
      <b-col cols="2" class="small-text email-options">
        <loading-gif :loading-name="activitiesLoadingName"></loading-gif>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName)">
          <b-form-group
            label="Contacter par"
          >
            <b-form-select v-model="selectedDisplay" class="small-text">
              <b-form-select-option :value="elt" v-for="elt of displayChoices" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName)" v-show="!showEntityAdresses && !showContacts">
          <b-form-group
            label="Séparateur"
            description="Les éléments seront séparés par ce caractère"
          >
            <emails-groups-separator-selector
              @change="selectedSeparator = $event.separator"
              small-text
            >
            </emails-groups-separator-selector>
          </b-form-group>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName) && !showEntityAdresses && showEmails">
          <b-form-group
            label="Taille des groupes d'emails"
            description="Attention! Un nombre trop grand peut générer des envois en spam"
          >
            <b-form-input
              type="number"
              v-model="emailsNumber"
              min="25"
              max="100"
              id="emailsNumber"
              class="small-text"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName) && !showEntityAdresses && showMobiles">
          <b-form-group
            label="Taille des groupes de SMS"
          >
            <b-form-input
              type="number"
              v-model="smsNumber"
              id="smsNumber"
              class="small-text"
            >
            </b-form-input>
          </b-form-group>
          <sms-list-operator-selector @change="smsSuffix = $event.smsSuffix" small-text>
          </sms-list-operator-selector>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName) && !showEntityAdresses">
          <b-form-group
            label=""
            label-for="onlyEmailContacts"
            description="Les contacts privilégiés ont cette case cochée sur leur fiche"
          >
            <b-form-checkbox v-model="onlyEmailContacts" id="onlyEmailContacts">
              Seulement aux contacts privilégiés
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName) && showEntityAdresses">
          <b-form-group
            label=""
            label-for="onlyNoEmails"
            description=""
          >
            <b-form-checkbox v-model="onlyNoEmails" id="onlyNoEmails">
              Seulement les familles sans adresse e-mail
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="select-box" v-if="!isLoading(activitiesLoadingName) && hasTeenagers">
          <b-form-group
            label="Destinataires ados"
            label-for="teenagersOption"
            description="pour les adolescents, définit à qui envoyer"
          >
            <b-form-select id="teenagersOption" v-model="teenagersOption" class="small-text">
              <b-select-option value="parents">aux parents</b-select-option>
              <b-select-option value="teenagers">à l'adolescent</b-select-option>
              <b-select-option value="both">à tous</b-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="select-box">
          <b-form-group
            label="Animateurs"
            label-for="includeActivityManagers"
          >
            <b-form-select
              id="includeActivityManagers"
              v-model="selectedManagerOption"
              class="small-select"
            >
              <b-select-option v-for="elt of managerOptions" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="select-box" v-if="showActivities">
          <b-form-group
            label="Inscriptions en attente et à l'essai"
            label-for="waitingOptions"
          >
            <b-form-select
              id="waitingOptions"
              v-model="selectedWaitingOption"
              class="small-select"
            >
              <b-select-option v-for="elt of waitingOptions" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="select-box">
          <b-row>
            <b-col>
              <b-form-group label-for="age" description="les années de naissance sont prises en compte">
                <b-checkbox name="age" v-model="ageFilter">Selon l'âge</b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="ageFilter">
            <b-col>
              <b-form-group
                label="De"
                label-for="ageFrom"
                :description="yearFrom"
              >
                  <number-input min="0" v-model="ageFrom"></number-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="à"
                label-for="ageTo"
                :description="yearTo"
              >
                  <number-input min="100" v-model="ageTo"></number-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="select-box" v-if="showYouthHomes">
          <b-row>
            <b-col>
              <b-form-group
                label-for="hasSeanceInscriptions"
                description="Seulement les enfants inscrits à au moins une séance Accueil de loisirs"
              >
                <b-checkbox name="hasSeanceInscriptions" v-model="hasSeanceInscriptions">Inscrits à une séance</b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="hasSeanceInscriptions">
            <b-col>
              <div class="help-text2">
                Si coché, seulement les inscriptions aux périodes choisies
              </div>
              <check-box-select
                id="periods"
                :choices="periods"
                @init="selectedPeriods = $event.choices"
                @changed="selectedPeriods = $event.choices"
              ></check-box-select>
            </b-col>
          </b-row>
          <b-row v-if="ageFilter">
            <b-col>
              <b-form-group
                label="De"
                label-for="ageFrom"
                :description="yearFrom"
              >
                  <number-input min="0" v-model="ageFrom"></number-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="à"
                label-for="ageTo"
                :description="yearTo"
              >
                  <number-input min="100" v-model="ageTo"></number-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="2" class="small-text">
        <br />
        <div class="select-box">
          <check-box-select
            id="schoolYears"
            :choices="schoolYears"
            :initial-value="initialYears"
            @init="selectedYears = $event.choices"
            @changed="selectedYears = $event.choices"
          ></check-box-select>
        </div>
        <div class="select-box" v-if="selectedYears.length && canViewAdhesions">
          <b-row>
            <b-col>
              <b-checkbox id="adhesions" v-model="showAdhesions">
                Adhésions
              </b-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <check-box-select
                id="adhesionTypes"
                v-if="showAdhesions"
                :choices="adhesionTypesChoices"
                @changed="adhesionTypesChanged($event)"
              ></check-box-select>
            </b-col>
          </b-row>
        </div>
        <div class="select-box" v-if="selectedYears.length && canViewActivities">
          <b-row>
            <b-col v-for="elt in activityCategories" :key="elt.id">
              <b-checkbox
                :id="'activityCategory' + elt.id"
                :checked="isActivityCategoryChecked(elt)"
                @change="checkActivityCategory(elt)"
              >
                {{ elt.label }}
              </b-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-checkbox
                id="showActivityHeirs"
                v-model="showActivityHeirs"
                v-if="showActivities && hasActivityHeirs"
                :disabled="onlyActivityHeirs"
              >
                Inclure les ateliers
              </b-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-checkbox
                id="onlyActivityHeirs"
                v-model="onlyActivityHeirs"
                v-if="showActivities && hasActivityHeirs"
                :disabled="showActivityHeirs"
              >
                Seulement les ateliers
              </b-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <vue-bootstrap-typeahead
                v-if="showActivities"
                v-model="activityNameFilter"
                :data="activityChoicesNames"
                ref="activityFilterTypeAhead"
                class="small-input-text"
              ></vue-bootstrap-typeahead>
            </b-col>
          </b-row>
          <check-box-select
            id="activities"
            v-if="showActivities"
            :choices="filteredActivityChoices"
            @changed="activitiesChanged($event)"
          ></check-box-select>
        </div>
        <div class="select-box" v-if="selectedYears.length && canViewYouthHomes">
          <b-row>
            <b-col>
              <b-checkbox
                id="showYouthHomes"
                v-model="showYouthHomes"
                :disabled="showYouthHomes && allYouthHomeActivities.length === 0"
              >
                {{ youthHomeLabel }}
              </b-checkbox>
            </b-col>
          </b-row>
          <check-box-select
            id="youthHomes"
            name="youthHomes"
            v-if="showYouthHomes"
            :choices="youthHomeActivityChoices"
            @changed="youthHomeActivitiesChanged($event)"
          ></check-box-select>
        </div>
        <div class="select-box" v-if="canViewTags">
          <b-row>
            <b-col>
              <b-checkbox id="showTags" v-model="showTags" :disabled="showTags && allTags.length === 0">
                Listes
              </b-checkbox>
            </b-col>
          </b-row>
          <check-box-select
            id="tags"
            name="tags"
            v-if="showTags"
            :choices="allTags"
            @changed="selectedTags = $event.choices"
          ></check-box-select>
          <b-checkbox
            id="includeAllContacts"
            v-model="includeAllContacts"
          >
            Inclure les contacts de l'annuaire
          </b-checkbox>
        </div>
        <div class="select-box" v-if="isPublicationActive">
          <b-row>
            <b-col>
              <b-checkbox id="showPublication" v-model="showPublication">
                {{ publicationTitle }}
              </b-checkbox>
            </b-col>
          </b-row>
          <div v-if="showPublication">
            <check-box-select
              id="publicationOptions"
              name="publicationOptions"
              :choices="publicationOptions"
              @changed="selectedPublicationOptions = $event.choices"
              v-if="showPublication"
            ></check-box-select>
            <b>Seulement depuis cette date</b>
            <b-input
              type="date"
              id="publicationSince"
              v-model="publicationSince"
            >
            </b-input>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import EmailsGroups from '@/components/Controls/EmailsGroups.vue'
import EmailsGroupsSeparatorSelector from '@/components/Controls/EmailsGroupsSeparatorSelector.vue'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import SmsList from '@/components/Controls/SmsList.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import { makeAdhesionType } from '@/types/adhesions'
import { makeActivity } from '@/types/activities'
import { makeChoice } from '@/types/base'
import { makeSchoolYear } from '@/types/schools'
import { distinctString, existsIn } from '@/utils/arrays'
import { makeIndividual, makeTag, makeEntity, makeEntityRole } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import { cellPhoneForSms } from '@/utils/phones'
import store from '@/store'
import SmsListOperatorSelector from '@/components/Controls/SmsListOperatorSelector.vue'
import { getDefaultActivityMenu } from '@/types/config'
import { makeSeancePeriod } from '@/types/youth'
import { comparePeriods } from '@/utils/sorting'

export default {
  name: 'ContactMembers',
  components: {
    SmsListOperatorSelector,
    NumberInput,
    PhoneLink,
    SmsList,
    EmailsGroupsSeparatorSelector,
    EmailsGroups,
    CounterLabel,
    EmailLink,
    CheckBoxSelect,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  mixins: [BackendMixin, ActivitiesMixin],
  data() {
    return {
      adhesionTypes: [],
      selectedAdhesionTypes: [],
      adhesionTypesChoices: [],
      allActivities: [],
      allYouthHomeActivities: [],
      selectedActivities: [],
      selectedYouthHomeActivities: [],
      selectedYears: [],
      contacts: [],
      activitiesLoadingName: 'contact-members-activities',
      contactsLoadingName: 'contact-members-contacts',
      activityNameFilter: '',
      schoolYears: [],
      showAdhesions: false,
      showActivityHeirs: false,
      onlyActivityHeirs: false,
      activityChoices: [],
      filteredActivityChoices: [],
      showYouthHomes: false,
      youthHomeActivityChoices: [],
      showTags: false,
      allTags: [],
      selectedTags: [],
      selectedSeparator: null,
      showEmails: false,
      showMobiles: false,
      membersAdhesionsLoading: 'membersAdhesions',
      membersAdhesionsCountLoading: 'membersAdhesionsCount',
      emailsNumber: 25,
      smsNumber: 0,
      smsSuffix: '',
      displayChoices: [
        makeChoice({ id: 1, name: 'Email ou SMS', }),
        makeChoice({ id: 2, name: 'Adresses postales', })
      ],
      selectedManagerOption: null,
      managerOptions: [
        makeChoice({ id: 0, name: 'Seulement les membres', }),
        makeChoice({ id: 1, name: 'Les membres et les animateurs', }),
        makeChoice({ id: 2, name: 'Seulement les animateurs', })
      ],
      selectedWaitingOption: null,
      waitingOptions: [
        makeChoice({ id: 3, name: 'Définitives et à l\'essai', }),
        makeChoice({ id: 0, name: 'Seulement définitives', }),
        makeChoice({ id: 4, name: 'Seulement les à l\'essai', }),
        makeChoice({ id: 5, name: 'Inclure les en attente', }), // toutes
        makeChoice({ id: 2, name: 'Seulement les en attente', }),
        makeChoice({ id: 6, name: 'Inclure les départs', }), // toutes
        makeChoice({ id: 7, name: 'Seulement les départ', })
        // makeChoice({ id: 3, name: 'Définitives et en attente', })
      ],
      selectedDisplay: null,
      showFamilyName: false,
      teenagersOption: 'parents',
      onlyEmailContacts: true,
      onlyNoEmails: false,
      ageFrom: null,
      ageTo: null,
      ageFilter: false,
      activityCategoriesMap: new Map(),
      includeAllContacts: false,
      hasSeanceInscriptions: false,
      periods: [],
      selectedPeriods: [],
      publicationOptions: [
        makeChoice({ id: 1, name: 'Dépositaires', }),
        makeChoice({ id: 2, name: 'Clients occasionnels', }),
        makeChoice({ id: 3, name: 'Abonnés', })
      ],
      publicationSince: '',
      selectedPublicationOptions: [],
      showPublication: false,
    }
  },
  async mounted() {
    this.selectedDisplay = this.displayChoices[0]
    this.selectedManagerOption = this.managerOptions[0]
    this.selectedWaitingOption = this.waitingOptions[0]
    await this.loadSchoolYears()
    await this.loadRoles()
  },
  watch: {
    selectedAdhesionTypes: function() {
      this.reset()
      this.loadContacts()
    },
    selectedActivities: function() {
      this.reset()
      this.loadContacts()
    },
    selectedYouthHomeActivities: function() {
      this.reset()
      this.loadContacts()
    },
    selectedTags: function() {
      this.reset()
      this.loadContacts()
    },
    showAdhesions: function(value) {
      this.reset()
      this.loadContacts()
      if (value) {
        this.loadAdhesionTypes()
      } else {
        this.selectedAdhesionTypes = []
      }
    },
    selectedActivityCategories: function(value) {
      this.reset()
      this.loadContacts()
      if (value && value.length) {
        this.loadActivities()
      } else {
        this.selectedActivities = []
        this.activityNameFilter = ''
        this.showActivityHeirs = false
        this.onlyActivityHeirs = false
      }
    },
    showActivityHeirs: function(value) {
      this.reset()
      this.loadContacts()
      this.loadActivities()
      if (!value) {
        this.selectedActivities = []
      }
    },
    onlyActivityHeirs: function(value) {
      this.reset()
      this.loadContacts()
      this.loadActivities()
      if (!value) {
        this.selectedActivities = []
      }
    },
    showYouthHomes: function(value) {
      this.reset()
      this.loadContacts()
      if (this.showYouthHomes) {
        this.loadYouthHomeActivities()
      } else {
        this.selectedYouthHomeActivities = []
      }
    },
    showEntityAdresses: function() {
      this.reset()
      this.loadContacts()
    },
    showTags: function() {
      this.reset()
      this.loadContacts()
      if (this.showTags) {
        this.loadTags()
      } else {
        this.selectedTags = []
      }
    },
    activityNameFilter: function() {
      this.selectedActivities = []
      this.filteredActivityChoices = this.getFilteredActivityChoices()
      this.reset()
    },
    selectedYears: function() {
      this.contacts = []
      this.reset()
      if (this.selectedYears.length) {
        this.activityChoices = this.getActivityChoices()
        this.filteredActivityChoices = this.getFilteredActivityChoices()
        this.youthHomeActivityChoices = this.getYouthHomeActivityChoices()
      } else {
        this.activityChoices = []
        this.filteredActivityChoices = []
        this.youthHomeActivityChoices = []
        this.showAdhesions = false
        this.showYouthHomes = false
        this.activityCategoriesMap = new Map()
        this.showActivityHeirs = false
        this.onlyActivityHeirs = false
      }
    },
    onlyEmailContacts() {
      this.reset()
      this.loadContacts()
    },
    selectedPublicationOptions() {
      this.reset()
      this.loadContacts()
    },
    publicationSince() {
      this.reset()
      this.loadContacts()
    },
    includeAllContacts() {
      this.reset()
      this.loadContacts()
    },
    onlyNoEmails() {
      this.reset()
      this.loadContacts()
    },
    teenagersOption() {
      this.reset()
      this.loadContacts()
    },
    selectedManagerOption() {
      this.reset()
      this.loadContacts()
    },
    selectedWaitingOption() {
      this.reset()
      this.loadContacts()
    },
    ageFilter() {
      if (!this.ageFilter) {
        this.reset()
        this.loadContacts()
      }
    },
    ageFrom() {
      if (this.ageFilter) {
        this.reset()
        this.loadContacts()
      }
    },
    ageTo() {
      if (this.ageFilter) {
        this.reset()
        this.loadContacts()
      }
    },
    hasSeanceInscriptions() {
      if (this.hasSeanceInscriptions && (this.periods.length === 0)) {
        this.loadPeriods()
      }
      this.reset()
      this.loadContacts()
    },
    selectedPeriods() {
      this.reset()
      this.loadContacts()
    },
  },
  computed: {
    showActivities() {
      return Array.from(this.activityCategoriesMap.values()).filter(elt => elt).length > 0
    },
    isPublicationActive() {
      return store.getters.config.isPublicationActive && this.hasPerm('publications.view_')
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    selectedActivityCategories() {
      const categories = []
      for (const cat of this.activityCategories) {
        if (this.isActivityCategoryChecked(cat)) {
          categories.push(cat)
        }
      }
      return categories
    },
    yearFrom() {
      if (this.ageFrom) {
        return '' + (moment().year() - +this.ageFrom)
      }
      return ''
    },
    yearTo() {
      if (this.ageTo) {
        return '' + (moment().year() - +this.ageTo)
      }
      return ''
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    hasActivityHeirs() {
      return this.allActivities.filter(elt => elt.heirs.length > 0).length > 0
    },
    showEntityAdresses() {
      return (this.selectedDisplay !== null) && (this.selectedDisplay.id === 2)
    },
    showContacts() {
      return !(this.showEmails || this.showMobiles)
    },
    activityChoicesNames() {
      return this.activityChoices.map(elt => elt.name)
    },
    canView() {
      return this.canViewAdhesions || this.canViewActivities || this.canViewYouthHomes || this.canViewTags
    },
    canViewAdhesions() {
      return this.hasPerm('adhesions.view_adhesion')
    },
    canViewActivities() {
      return this.hasPerm('activities.view_coreactivityinscription')
    },
    canViewYouthHomes() {
      return this.hasPerm('youth.view_youthhomeactivityinscription')
    },
    canViewTags() {
      return this.hasPerm('people.view_tag')
    },
    emails() {
      return distinctString(this.contacts.filter(elt => elt.individual.email).map(elt => elt.individual.email))
    },
    mobilePhones() {
      return distinctString(
        this.contacts.filter(
          elt => elt.individual.cellPhone
        ).map(
          elt => cellPhoneForSms(elt.individual.cellPhone)
        )
      )
    },
    initialYears() {
      if (this.schoolYears.length === 1) {
        return this.schoolYears
      } else {
        return []
      }
    },
    hasTeenagers() {
      return store.getters.roles.filter(elt => elt.isChild && elt.hasContact).length
    },
    activityCategories() {
      let menus = this.activityMenus
      if (menus.length === 0) {
        menus = [getDefaultActivityMenu()]
      }
      return menus
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEntityRoles']),
    yearsChanged(event) {
      this.selectedYear = event.choices
      this.loadContacts()
    },
    isActivityCategoryChecked(category) {
      return !!this.activityCategoriesMap.get(category.id)
    },
    checkActivityCategory(category) {
      const isChecked = this.isActivityCategoryChecked(category)
      this.activityCategoriesMap.set(category.id, !isChecked)
      this.activityCategoriesMap = new Map(this.activityCategoriesMap)
    },
    async loadRoles() {
      if (store.getters.roles.length === 0) {
        try {
          const backendApi = new BackendApi('get', '/api/people/roles/')
          const resp = await backendApi.callApi()
          this.setEntityRoles(resp.data.map(elt => makeEntityRole(elt)))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadAdhesionTypes() {
      this.startLoading(this.activitiesLoadingName)
      const url = '/api/adhesion-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.adhesionTypes = resp.data.map(elt => makeAdhesionType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.activitiesLoadingName)
      this.adhesionTypesChoices = this.getAdhesionTypeChoices()
    },
    async loadActivities() {
      this.startLoading(this.activitiesLoadingName)
      this.allActivities = []
      for (const elt of this.selectedActivityCategories) {
        const url = '/api/activities/activities/?category=' + elt.category
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.allActivities = this.allActivities.concat(resp.data.map(elt => makeActivity(elt)))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      this.endLoading(this.activitiesLoadingName)
      this.activityChoices = this.getActivityChoices()
      this.filteredActivityChoices = this.getFilteredActivityChoices()
    },
    async loadYouthHomeActivities() {
      this.startLoading(this.activitiesLoadingName)
      const url = '/api/youth/activities/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allYouthHomeActivities = resp.data.map(elt => makeActivity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.activitiesLoadingName)
      this.youthHomeActivityChoices = this.getYouthHomeActivityChoices()
    },
    async loadTags() {
      this.tags = []
      if (this.hasPerm('people.view_tag')) {
        const url = '/api/people/tags/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.allTags = resp.data.map(elt => makeTag(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadContacts() {
      this.startLoading(this.contactsLoadingName)
      this.contacts = []
      this.entities = []
      let adhesionTypes = this.selectedAdhesionTypes.map(elt => elt.id).join(',')
      let activities = this.selectedActivities.map(elt => elt.id).join(',')
      let youthHomes = this.selectedYouthHomeActivities.map(elt => elt.id).join(',')
      let schoolYears = this.selectedYears.map(elt => elt.id).join(',')
      let tags = this.selectedTags.map(elt => elt.id).join(',')
      let url = '/contact/api/contact-members/?'
      if (this.showEntityAdresses) {
        url = '/contact/api/contact-entities/?'
        if (this.onlyNoEmails) {
          url += '&only-no-emails=1'
        }
      }
      if (this.showActivities && activities) {
        url += '&activities=' + activities
        if (this.selectedWaitingOption) {
          url += '&waiting-inscriptions=' + this.selectedWaitingOption.id
        }
      }
      if (this.showYouthHomes && youthHomes) {
        url += '&youth-home-activities=' + youthHomes
      }
      if (this.showAdhesions) {
        url += '&adhesions=' + schoolYears
        if (adhesionTypes) {
          url += '&adhesion-types=' + adhesionTypes
        }
      }
      if (this.showTags && tags) {
        url += '&tags=' + tags
      }
      if (this.teenagersOption) {
        url += '&teenagers=' + this.teenagersOption
      }
      if (!this.onlyEmailContacts) {
        url += '&only_email_contacts=0'
      }
      if (this.selectedManagerOption && this.selectedManagerOption.id) {
        url += '&activity-managers=' + this.selectedManagerOption.id
      }
      if (this.ageFilter && this.ageFrom) {
        url += '&age-from=' + this.ageFrom
      }
      if (this.ageFilter && this.ageTo) {
        url += '&age-to=' + this.ageTo
      }
      if (this.includeAllContacts) {
        url += '&all_contacts=1'
      }
      if (this.hasSeanceInscriptions) {
        url += '&seance_ins=1'
        if (this.selectedPeriods.length) {
          url += '&periods=' + (this.selectedPeriods.map(elt => '' + elt.id)).join(',')
        }
      }
      if (this.showPublication && this.selectedPublicationOptions.length) {
        const selectedPubOptions = this.selectedPublicationOptions.map(elt => elt.id).join('-')
        url += '&publication_contacts=' + selectedPubOptions
        if (this.publicationSince) {
          url += '&publication_since=' + this.publicationSince
        }
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.contacts = resp.data.map(elt => {
          return {
            individual: elt.individual ? makeIndividual(elt.individual) : null,
            entity: elt.entity ? makeEntity(elt.entity) : null,
            individuals: elt.individuals ? elt.individuals.map(sub => makeIndividual(sub)) : [],
          }
        })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.contactsLoadingName)
    },
    activitiesChanged(event) {
      this.selectedActivities = event.choices
    },
    adhesionTypesChanged(event) {
      this.selectedAdhesionTypes = event.choices
    },
    youthHomeActivitiesChanged(event) {
      this.selectedYouthHomeActivities = event.choices
    },
    onSend() {
      if (this.contacts.length) {
        this.showEmails = true
      }
    },
    onSms() {
      if (this.contacts.length) {
        this.smsNumber = this.mobilePhones.length
        this.showMobiles = true
      }
    },
    reset() {
      this.showEmails = false
      this.showMobiles = false
    },
    onBack() {
      this.reset()
    },
    getAdhesionTypeChoices() {
      const hasSeveralYears = this.selectedYears.length > 1
      return this.adhesionTypes.map(
        elt => makeChoice(
          {
            id: elt.id,
            name: elt.name + (hasSeveralYears ? ' ' + elt.schoolYear.name : ''),
          }
        )
      )
    },
    getActivityChoices() {
      const hasSeveralYears = this.selectedYears.length > 1
      const schoolYearIds = this.selectedYears.map(elt => elt.id)
      let allActivities = []
      if (this.showActivityHeirs) {
        for (const activity of this.allActivities) {
          const items = [activity].concat(activity.heirs)
          allActivities = allActivities.concat(items)
        }
      } else if (this.onlyActivityHeirs) {
        for (const activity of this.allActivities) {
          allActivities = allActivities.concat(activity.heirs)
        }
      } else {
        allActivities = this.allActivities
      }
      return allActivities.filter(
        elt => existsIn([elt.schoolYear.id], schoolYearIds)
      ).map(
        elt => makeChoice(
          {
            id: elt.id,
            name: elt.name + (hasSeveralYears ? ' ' + elt.schoolYear.name : ''),
          }
        )
      )
    },
    getYouthHomeActivityChoices() {
      const hasSeveralYears = this.selectedYears.length > 1
      const schoolYearIds = this.selectedYears.map(elt => elt.id)
      return this.allYouthHomeActivities.filter(
        elt => existsIn([elt.schoolYear.id], schoolYearIds)
      ).map(
        elt => makeChoice(
          {
            id: elt.id,
            name: elt.name + (hasSeveralYears ? ' ' + elt.schoolYear.name : ''),
          }
        )
      )
    },
    getFilteredActivityChoices() {
      return this.activityChoices.filter(
        elt => {
          return (
            (this.activityNameFilter.length === 0) ||
            (this.selectedActivities.map(selElt => selElt.id).indexOf(elt.id) >= 0) ||
            (elt.name.toUpperCase().indexOf(this.activityNameFilter.toUpperCase()) >= 0)
          )
        }
      )
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async toExcel() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'contacts'
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getContactName(contact) {
      if (contact.individuals.length === 1) {
        return contact.individuals[0].firstAndLastName()
      } else {
        return contact.entity.members
      }
    },
    getLinks() {
      if (this.showContacts) {
        return [
          {
            id: 3,
            label: 'Excel',
            callback: this.toExcel,
            icon: 'far fa-file-excel',
            cssClass: (this.contacts.length === 0) ? 'btn-disabled' : 'btn-secondary',
          },
          {
            id: 1,
            label: 'Emails',
            callback: this.onSend,
            icon: 'fa fa-send',
            cssClass: (this.contacts.length === 0 || this.showEntityAdresses) ? 'btn-disabled' : 'btn-primary',
          },
          {
            id: 2,
            label: 'SMS',
            callback: this.onSms,
            icon: 'fas fa-mobile-alt',
            cssClass: (this.contacts.length === 0 || this.showEntityAdresses) ? 'btn-disabled' : 'btn-primary',
          }
        ]
      } else {
        return [
          {
            id: 99,
            label: 'Retour',
            callback: this.onBack,
            icon: 'fa fa-chevron-left',
            cssClass: 'btn-secondary',
          }
        ]
      }
    },
    async loadPeriods() {
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.periods = resp.data.map(makeSeancePeriod).sort(comparePeriods)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less" scoped>
.select-box {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #c0c0c0;
}

.select-box:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.contact-line {
  padding: 5px;
  border-bottom: solid 1px #c0c0c0;
}

.contact-line:nth-of-type(even) {
  background: #fff;
}

.contact-line:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

a.btn-disabled {
  color: #ccc !important;
  border: solid 1px #ccc;
  display: inline-block;
}
.contact-list {
  font-size: 14px;
}
.small-text {
  font-size: 12px;
}
.email-options {
  padding-top: 10px;
  background: #ccc;
}
</style>
